import styled from '@emotion/styled'

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  .social-container {
    font-size: 2.7rem;
    margin-top: 6.5rem;

    a {
      color: white;
      text-decoration: none;
      cursor: pointer;
      margin-right: 2.5rem;

      &:hover {
        color: $color-blue-light-1;
        transition: 0.2s;
      }
    }

    @media screen and (max-width: 1200px) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
    }
  }

  h2 {
    color: white;
    font-size: 2.3rem;
    font-weight: 400;
    margin-top: 3rem;
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-size: 1.5rem;
    }
  }
`

export default LeftContainer
