import React from 'react'
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa'
export default [
  {
    icon: <FaFacebookF />,
    url: 'https://www.facebook.com/FluentWorlds.latam',
  },
  {
    icon: <FaInstagram />,
    url: 'https://www.instagram.com/aprendeinglesfw/?igshid=7d7sxkhpga0m',
  },
  {
    icon: <FaYoutube />,
    url: 'https://www.youtube.com/channel/UCxc4rHycVQMSkpLWj0AKFZQ',
  },
]
