import React from 'react'
import { Helmet } from 'react-helmet'
import Header from './Header'
import Footer from '../components/Footer'
import '../sass/layout.scss'

const Layout = ({ children }) => {
  return (
    <main>
      <Helmet>
        <title>MS | FluentWorlds</title>
      </Helmet>
      <Header />
      {children}
      <Footer />
    </main>
  )
}

export default Layout
