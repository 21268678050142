import styled from '@emotion/styled'

const LinksContainer = styled.div`
  display: flex;
  font-size: 2.3rem;
  text-transform: capitalize;
  margin-top: 2rem;

  a {
    text-decoration: none;
    color: white;
    margin-left: 5rem;

    &:hover {
      color: $color-blue-light-1;
      transition: 0.2s;
    }
  }

  @media screen and (max-width: 1200px) {
    margin-bottom: 4rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;

    a {
      padding-bottom: 3rem;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 575px) {
    font-size: 2rem;

    a {
      padding-bottom: 2rem;
    }
  }
`

export default LinksContainer
