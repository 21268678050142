import styled from '@emotion/styled'

const SocialContainer = styled.div`
  font-size: 2.7rem;
  margin-top: 6.5rem;

  a {
    color: white;
    text-decoration: none;
    cursor: pointer;
    margin-right: 2.5rem;

    &:hover {
      color: $color-blue-light-1;
      transition: 0.2s;
    }
  }

  @media screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
`

export default SocialContainer
