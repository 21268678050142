import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useAuth0 } from '../../react-auth0-spa'
import Modal from 'react-responsive-modal'
import socialIcons from '../../constants/social-icons'
import fwLogo from '../../images/png/fluentworlds_footer.png'
import FooterContainer from './FooterContainer'
import PrivacyNotice from './PrivacyNotice'
import TopContainer from './TopContainer'
import LinksContainer from './LinksContainer'
import ColumnContainer from './ColumnContainer'
import BottomContainer from './BottomContainer'
import LeftContainer from './LeftContainer'
import SocialContainer from './SocialContainer'
import ImageContainer from './ImageContainer'
import PrivacyContainer from './PrivacyContainer'
import PrivacyButtonContainer from './PrivacyButtonContainer'

const Footer = () => {
  const { isAuthenticated } = useAuth0()
  const [modalIsOpen, setIsOpen] = useState(false)

  const onOpenModal = () => {
    setIsOpen(true)
  }

  const onCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <FooterContainer>
      <Modal open={modalIsOpen} onClose={onCloseModal}>
        <PrivacyNotice />
      </Modal>
      <TopContainer>
        <img src={fwLogo} alt="fluent worlds" />
        <LinksContainer>
          <ColumnContainer>
            <Link to="/" activeStyle={{ color: '#0080fa' }}>
              inicio
            </Link>
          </ColumnContainer>
          <ColumnContainer>
            <Link to="/products" activeStyle={{ color: '#0080fa' }}>
              productos
            </Link>
          </ColumnContainer>
          <ColumnContainer>
            <Link to="/we" activeStyle={{ color: '#0080fa' }}>
              nosotros
            </Link>
            <Link to="/frequentQuestions" activeStyle={{ color: '#0080fa' }}>
              Preguntas Frecuentes
            </Link>
          </ColumnContainer>
          <ColumnContainer>
            <Link to="/contact" activeStyle={{ color: '#0080fa' }}>
              contacto
            </Link>
          </ColumnContainer>
          {isAuthenticated && (
            <ColumnContainer>
              <Link to="/profile" activeStyle={{ color: '#0080fa' }}>
                mi perfil
              </Link>
            </ColumnContainer>
          )}
        </LinksContainer>
      </TopContainer>

      <BottomContainer>
        <LeftContainer>
          <SocialContainer>
            {socialIcons.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.icon}
                </a>
              )
            })}
          </SocialContainer>
          <h2>
            {' '}
            Propiedad Intelectual 2020 FluentWorlds | Todos los derechos
            reservados
          </h2>
        </LeftContainer>
        <ImageContainer />
      </BottomContainer>
      <PrivacyContainer>
        <PrivacyButtonContainer onClick={onOpenModal}>
          Aviso de Privacidad
        </PrivacyButtonContainer>
      </PrivacyContainer>
    </FooterContainer>
  )
}

export default Footer
