import styled from '@emotion/styled'

const PrivacyButtonContainer = styled.div`
  font-size: 1.5rem;
  margin-top: 3rem;
  background-color: Transparent;
  color: #0080fa;
  outline: none;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    justify-content: center;
  }

  @media screen and (max-width: 575px) {
    font-size: 1.2rem;
    margin-top: 1.5rem;
  }
`

export default PrivacyButtonContainer
