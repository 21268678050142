import React from 'react'
import styled from '@emotion/styled'
import msLogo from '../../images/png/ms_col_footer.png'

const ImageContainer = () => {
  const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;

    h4 {
      color: white;
      font-size: 2.3rem;
      font-weight: 400;
      margin-right: 2rem;
      width: 15rem;
      text-align: right;
    }

    img {
      height: 5rem;
    }

    @media screen and (max-width: 1200px) {
      margin-top: 2rem;
    }

    @media screen and (max-width: 768px) {
      h4 {
        font-size: 2rem;
        width: 18rem;
      }
    }

    @media screen and (max-width: 575px) {
      h4 {
        font-size: 1.6rem;
        width: 15rem;
      }

      img {
        height: 4rem;
      }
    }
  `

  return (
    <Container>
      <h4>Traido a ustedes por</h4>
      <img src={msLogo} alt="MS INTERNATIONAL COLOMBIA" />
    </Container>
  )
}

export default ImageContainer
