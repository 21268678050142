import styled from '@emotion/styled'

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #213054;
  padding-top: 9rem;
  padding-bottom: 8rem;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
`
export default FooterContainer
