import React from 'react'
import styled from '@emotion/styled'

const PrivacyNotice = () => {
  const ModalContainer = styled.div`
    h4 {
      font-size: 2.5rem;
      text-align: center;
      color: #0080fa;
      margin-bottom: 3rem;
    }

    h6 {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      margin-top: 2rem;
    }

    p {
      font-size: 1.4rem;
      margin-bottom: 1rem;
      padding-left: 1rem;
    }

    ul {
      padding-left: 3.5rem;
      li {
        font-size: 1.4rem;
      }
    }

    a {
      text-decoration: none;
      color: #0080fa;
    }

    margin-bottom: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @media screen and (max-width: 575px) {
      h4 {
        font-size: 2rem;
      }

      h6 {
        font-size: 1.3rem;
      }

      p {
        font-size: 1.2rem;
      }

      ul {
        li {
          font-size: 1.2rem;
        }
      }

      margin-bottom: 2rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  `
  return (
    <ModalContainer>
      <h4>AVISO DE PRIVACIDAD</h4>
      <p>
        En cumplimiento de las normas colombianas en materia de protección de
        datos personales, y en especial la Ley 1581 de 2012 y el Decreto 1074 de
        2015 y demás normas que las modifiquen o complementen, informamos a los
        titulares de datos personales sobre la existencia de nuestra POLITICA DE
        TRATAMIENTO DE DATOS PERSONALES, la cual es un documento público que
        puede ser consultado en todo momento a través de nuestra página web{' '}
        <a
          href="https://www.fluentworlds.co"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.fluentworlds.co
        </a>
      </p>
      <div>
        <h6>1. RAZON SOCIAL Y DATOS DE CONTACTO:</h6>
        <p>
          FLUENTWORLDS COLOMBIA / EDIVAL
          <br />
          Email:{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:contacto@FluentWorlds.co"
          >
            contacto@FluentWorlds.co
          </a>
          <br />
          Ciudad y Pais: Cali – Valle - Colombia
          <br />
          Horarios. Lunes a Viernes de 9:00 am a 5:00 pm
        </p>
        <h6>2. FINALIDAD DE LA RECOLECCIÓN Y TIPO DE TRATAMIENTO:</h6>
        <p>
          Los datos personales de clientes que en adelante se recojan o que se
          encuentren en nuestras bases de datos serán tratados con las
          siguientes finalidades:
        </p>
        <p>FLUENTWORLDS / EDIVAL usará la información que suministre para:</p>
        <p>
          2.1. Efectuar las gestiones pertinentes para el desarrollo de la etapa
          precontractual, contractual con FLUENTWORLDS / EDIVAL, en especial en
          cuanto corresponde a gestiones internas de creación del código de
          usuario, respecto de cualquiera de los productos que haya o no
          adquirido o, respecto de cualquier relación contractual subyacente que
          tenga con ella, así como dar cumplimiento a la ley Colombiana o
          extranjera y las órdenes de autoridades judiciales o administrativas.
        </p>
        <p>
          2.2. Gestionar trámites (solicitudes, quejas, reclamos), realizar
          análisis de riesgo, efectuar encuestas de satisfacción respecto de los
          productos y servicios de la empresa, así como sus aliados comerciales.
        </p>
        <p>
          2.3. Suministrar información de contacto y documentos pertinentes a la
          fuerza comercial y/o red de distribución, tele-mercadeo, investigación
          de mercados y cualquier tercero con el cual la empresa posea un
          vínculo contractual de cualquier índole.
        </p>
        <p>
          2.4. Dar a conocer, transferir y/o transmitir datos personales dentro
          y fuera del país, a terceros a consecuencia de un contrato, ley o
          vínculo lícito que así lo requiera, para la presentación de los
          servicios respectivos o en virtud de acuerdos o alianzas comerciales.
        </p>
        <p>
          2.5. Realizar a través de cualquier medio en forma directa o a través
          de terceros, programación y prestación de servicio técnico, venta,
          seguimiento al desempeño del producto, inteligencia de negocios,
          actividades de mercadeo, promoción o publicidad, mejoramiento del
          servicio, verificación, consulta y control, habilitación de medios de
          pago así como cualquier otra relacionada con nuestros productos o
          servicios actuales y futuros, para el cumplimiento de las obligaciones
          contractuales y del objeto social de la empresa.
        </p>
        <p>
          2.6. Para el envío de confirmaciones sobre el inicio o terminación del
          servicio de acuerdo a los requerimientos de las funciones demandadas y
          sostenibilidad de los negocios de la compañía.
        </p>
        <p>
          2.7. Controlar y prevenir el fraude en cualquiera de sus modalidades.
        </p>
        <h6>3. DERECHOS DE LOS TITULARES DE LA INFORMACIÓN</h6>
        <p>EL titular de la información tiene los siguientes derechos:</p>
        <p>
          3.1. Conocer el tratamiento al cual serán sometidos los datos
          personales recolectados y la finalidad de los mismos.
        </p>
        <p>3.2. Solicitar actualización y rectificación de la información.</p>
        <p>
          3.3. Revocar la autorización y/o solicitar la supresión de los datos,
          al menos que exista un deber legal o contractual que haga imperativo
          conservar la información.
        </p>
        <p>
          3.4. Abstenerse a responder preguntas sobre datos sensibles o sobre
          datos de los niños, niñas o adolescentes.
        </p>
        <p>3.5. Otros.</p>
        <h6>
          4. MECANISMOS PARA CONOCER LA POLÍTICA Y LOS CAMBIOS QUE SE PRODUZCAN
        </h6>
        <p>
          El presente aviso y la política de tratamiento de datos personales,
          así como sus cambios o modificaciones serán comunicados a los
          titulares, a través de los siguientes medios:
        </p>
        <ul>
          <li>Página web.</li>
          <li>Email directo.</li>
          <li>
            Información sobre cómo consultarla al momento de recolectar los
            datos.
          </li>
        </ul>
      </div>
    </ModalContainer>
  )
}

export default PrivacyNotice
