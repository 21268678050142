import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { FaBars } from 'react-icons/fa'
import Loader from 'react-loader-spinner'

import { useAuth0 } from '../react-auth0-spa'
import UserContext from '../context/user/userContext'
import logo from '../images/png/fluentworlds_banner.png'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    auth0Loading,
  } = useAuth0()

  const { username, fetchUserFromFirestore, userStateLoading } = useContext(
    UserContext,
  )

  // when user is authenticated, fetch the user from DB
  useEffect(() => {
    if (user && username === '') {
      fetchUserFromFirestore()
    }
    // eslint-disable-next-line
  }, [user])

  const toggleNav = () => {
    setIsOpen(!isOpen)
  }

  const NavbarContainer = styled.nav`
    margin: 2rem 2rem 0 2rem;
    padding-bottom: 1rem;
    background-color: transparent;

    @media screen and (max-width: 992px) {
      ${isOpen ? 'margin-top: 2rem' : 'margin-top: 15rem'}
    }
  `

  const NavCenter = styled.div`
    @media screen and (min-width: 992px) {
      background-color: transparent;
      max-width: 150rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  `

  const NavHeader = styled.div`
    display: flex;
    align-items: center;
    height: 8rem;

    @media screen and (max-width: 1500px) {
      img {
        height: 10rem;
      }
    }

    @media screen and (max-width: 1450px) {
      img {
        display: none;
      }
    }
  `
  const LogoButton = styled.button`
    margin-top: 4.5em;
    margin-left: 0.5em;
    margin-right: auto;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  `

  const BurgerIcon = styled(FaBars)`
    margin-left: auto;
    margin-right: auto;
    font-size: 3rem;

    @media screen and (min-width: 992px) {
      display: none;
    }

    @media screen and (max-width: 992px) {
      margin-bottom: 2em;
      position: absolute;
      top: 22px;
      left: 16px;
    }

    @media screen and (max-width: 550px) {
      margin-bottom: 2em;
    }
  `

  const UserAndButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 17rem;

    @media screen and (max-width: 992px) {
      position: absolute;
      top: 1em;
      right: 16px;
    }
  `
  const NavLinks = styled.div`
    background-color: transparent;
    list-style-type: none;
    transition: all 0.3s linear;
    height: 0;
    overflow: hidden;
    font-size: 2.5rem;
    text-decoration: none;

    li {
      a {
        text-decoration: none;
        margin-right: 5rem;
      }
    }

    a {
      color: #003699;
      display: block;
      padding: 1rem 1.25rem;
      text-decoration: none;
      text-transform: capitalize;
      font-weight: bold;
    }

    @media screen and (max-width: 1500px) {
      font-size: 2rem;
      text-transform: capitalize;
    }

    @media screen and (min-width: 992px) {
      height: auto;
      display: flex;
      list-style-type: none;
    }

    ${isOpen ? 'height: 37rem' : ''}
  `
  const User = styled.span`
    font-size: 1.5rem;
    margin-right: 2rem;
    margin-bottom: 0.3rem;
  `

  const SignUpButtonWrapper = styled.button`
    position: relative;
    right: 16px;
    outline: none;
    cursor: pointer;

    @media screen and (max-width: 992px) {
      position: absolute;
      top: 1em;
      right: 16px;
    }
  `

  const SignOutButtonWrapper = styled.button`
    outline: none;
    cursor: pointer;
  `

  const LoaderWrapperSignUp = styled.button`
    position: relative;
    border: none;
    outline: none;
    background: transparent;
    right: 16px;
    @media screen and (max-width: 992px) {
      position: absolute;
      top: 1em;
      right: 16px;
    }
  `

  const LoaderWrapperSignIn = styled.button`
    position: relative;
    border: none;
    outline: none;
    background: transparent;
    right: 16px;
    @media screen and (max-width: 992px) {
      position: absolute;
      top: 1em;
      right: 16px;
    }
  `

  const LoadingIndicatorSignUp = (
    <LoaderWrapperSignUp>
      <Loader type="ThreeDots" color="#4A90E2" height={50} width={50} />
    </LoaderWrapperSignUp>
  )

  const LoadingIndicatorSignIn = (
    <LoaderWrapperSignIn>
      <Loader type="ThreeDots" color="#4A90E2" height={50} width={50} />
    </LoaderWrapperSignIn>
  )

  const renderAuthenticationNavLinks = isAuthenticated => {
    if (isAuthenticated) {
      return (
        <li>
          <Link
            to="/profile"
            activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
          >
            Mi Perfil
          </Link>
        </li>
      )
    } else {
      return (
        <li>
          <a href="#signin" onClick={() => loginWithRedirect()}>
            Iniciar sesión
          </a>
        </li>
      )
    }
  }

  const renderAuthenticationButton = isAuthenticated => {
    if (isAuthenticated && username !== '') {
      return (
        <UserAndButtonContainer>
          <User>Hello {username}</User>
          <SignOutButtonWrapper className="btn-danger" onClick={() => logout()}>
            Finalizar la Sesión
          </SignOutButtonWrapper>
        </UserAndButtonContainer>
      )
    } else {
      return (
        <SignUpButtonWrapper
          className="btn-success"
          onClick={() => loginWithRedirect({ mode: 'signUp' })}
        >
          Registrarme
        </SignUpButtonWrapper>
      )
    }
  }

  return (
    <NavbarContainer>
      <NavCenter>
        <NavHeader>
          <Link to="/">
            <img src={logo} alt="fluentworlds.co" />
          </Link>
          <LogoButton onClick={toggleNav}>
            <BurgerIcon />
          </LogoButton>
        </NavHeader>
        <NavLinks>
          <li>
            <Link to="/" activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}>
              inicio
            </Link>
          </li>
          <li>
            <Link
              to="/products"
              activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
            >
              productos
            </Link>
          </li>
          <li>
            <Link
              to="/we"
              activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
            >
              nosotros
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
            >
              contacto
            </Link>
          </li>
          {auth0Loading
            ? LoadingIndicatorSignIn
            : renderAuthenticationNavLinks(isAuthenticated)}
        </NavLinks>

        {auth0Loading || userStateLoading
          ? LoadingIndicatorSignUp
          : renderAuthenticationButton(isAuthenticated)}
      </NavCenter>
    </NavbarContainer>
  )
}

export default Header
