import styled from '@emotion/styled'

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 71%;

  @media screen and (max-width: 1350px) {
    width: 90%;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  img {
    height: 8rem;
    width: 35rem;
    text-align: center;
  }

  @media screen and (max-width: 575px) {
    img {
      height: 6rem;
      width: 26rem;
      text-align: center;
    }
  }
`

export default TopContainer
