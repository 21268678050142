import styled from '@emotion/styled'

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 0.5rem;
  }
`

export default ColumnContainer
