import styled from '@emotion/styled'

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 71%;

  @media screen and (max-width: 1350px) {
    width: 90%;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`

export default BottomContainer
